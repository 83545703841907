button.colored-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--accent-primary);
  color: var(--text-white);
  outline: none;
  border: none;
  border-radius: var(--bradius-xxxl);
  padding: var(--spacing-md) 0;

  svg {
    height: 35px;
    fill: var(--white);
  }
}

button.colored-button-inverse {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-app);
  color: var(--text-white);
  outline: none;
  border: 1px solid var(--accent-primary);
  border-radius: var(--bradius-xxxl);
  padding: 0 50px;
  padding: 8px 30px;

  p {
    margin-right: 5px;
  }

  svg {
    height: 35px;
    fill: var(--accent-primary);
  }
}