.balance-container {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  border-radius: 15px;
  position: relative;
  box-shadow: 0 5px 5px var(--shadow);
  background-color: transparent;
  
  .img-bg{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border-radius: 15px;
    background-color: transparent;
  }
  
  > .children {
    padding: var(--spacing-xl);
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 15px;
    background-color: transparent;
  }

  > img {
    height: 100%;
    border-radius: 15px;
    background-color: transparent;
  }
}

.screen-con {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;

  .nav-con{
    display: flex;
    flex-direction: row;
    margin: 0 var(--spacing-xl);
    justify-content: space-between;
    height: var(--spacing-xnft-header-height);
    align-items: center;

    .go-back {
      display: flex;
      justify-content: center;

      svg{
        width: 25px;
        height: 25px;
      }
    }
  }
}