
.filters-panel {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
  height: 100%;

  * {
    &::-webkit-scrollbar {
      width: 3px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--light-gray);
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--accent-primary);
    }
  }

  .custom-select {
    background: none;
    padding: 0 6px 0 0;
    
    .select__single-value {
      color: white;

    }
    
    .select__control {
      padding: 6px 8px;
      border-radius: 8px;
      border: 1px solid rgba(242, 242, 242, 0.20);
      background: none;
    }

    .select__menu {
      background: var(--background-modal);
      z-index: 5;

      // > * {
      //   // z-index: 11;
      //   color: white;
      // }
    }

    input {
      color: white !important;
    }

  }

  .filters {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow: auto;
    padding: 0 6px 0 0;

    > * {
      display: flex;
      flex-direction: column;
      padding: 12px 16px;
      border-radius: 8px;
      border: 1px solid var(--greyscale-strokegrey, rgba(255, 255, 255, 0.20));

      .header-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .header {
          overflow-wrap: anywhere;
          flex-shrink: 1;
        }

        > div {
          flex-shrink: 0;
          cursor: pointer;
        }
      }

      .string-options {
        display: grid;
        gap: 20px;
        grid-template-columns: 1fr 1fr 1fr;
        grid-auto-rows: auto;

        > div {
          background: var(--background-modal);
          border-radius: 4px;
          min-height: 50px;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          overflow: hidden;
          padding: 0 3px;

          &.active {
            background: var(--accent-primary);
          }

          p {
            text-align: center;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }

      .num-options {
        margin: 10px 0;
      }
    }
  }

  .filters-controls {
    display: flex;
    align-items: center;
    margin-top: auto;
    bottom: 20px;
    background: var(--background-input);
    width: 100%;
    padding: 10px 0 35px 0;
    gap: 20px;

    .colored-button {
      font-weight: 600;
    }

    > p {
      cursor: pointer;
      text-align: center;
    }

    > * {
      flex: 1 0 0;
    }
  }
}