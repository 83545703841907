.faq-page {
  padding: 15px;
  display: flex;
  flex-direction: column;

  > .search-input-container {
    margin-top: 0;
  }

  .faq-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--gray);
    padding: 20px 10px;

    > svg {
      transform: rotate(180deg);
    }
  }
}

.faq-view {
  > .banner {
    margin-bottom: 30px;
  }

  .content {
    p {
      margin: 1em 0;
      color: var(--text-white);
    }
  }
}