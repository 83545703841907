.manage-wallets{
  display: flex;
  flex-direction: column;
  padding: 0 var(--spacing-xl) var(--spacing-xl) var(--spacing-xl);
  height: 100%;

  .mr {
    margin-top: 35px;
  }

  .wallet-card {
    display: flex;
    padding: 10px;
    border: 1px solid var(--gray);
    margin: 15px 0;
    border-radius: 16px;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;
    }

    svg {
      transform: rotate(180deg);
      margin-left: 10px;
    }
  }

  .connected-wallet {
    display: flex;
    flex-direction: column;
  }

  .wallets {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 35px;
  }

  > button {
    margin-top: auto;
  }
}