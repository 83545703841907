.myshop {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-xl);
  height: 100%;

  > div.row {
    display: flex;
    align-items: center;

    > .avatar-container {
      width: 70px;
      height: 70px;
      margin-right: 10px;
    }
  }

  .tabs {
    margin: 20px 0 0 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--gray);
    padding-bottom: 5px;

    > div {
      position: relative;
    }

    p {
      color: var(--text-gray);
    }

    > div.active {

      p {
        color: var(--text-white);
      }
      div {
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: -6px;
        background-image: linear-gradient(90deg, rgb(138, 53, 19), rgb(238, 157, 18));
      }
    }
  }
}

.profile-listings {
  display: flex;
  flex-wrap: wrap;
  // height: 100%;

  > a {
    height: fit-content;
    align-self: flex-start;
  }
  
  .empty-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

  }

  .empty {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
      margin: 15px 0;
      text-align: center;
    }
  }
}

.profile-activity {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  align-items: center;

  .empty {
    margin-top: 20px;
  }

  > .activity-item {
    margin: 10px 0;
    padding: 10px;
    width: 100%;
    max-width: 500px;
    background: var(--background-modal);
    display: flex;
    align-items: center;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 3px;

    .gr {
      color: var(--text-gray);
    }

    img {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      margin-right: 10px;
    }
  }
}

.profile-about {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  .cell {
    background: var(--background-input);
    border-radius: 14px;
    padding: 10px;
    margin: 10px 0;
  }

  .gr {
    color: var(--text-gray);
  }

  .sep {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      svg {
        margin: 0 5px;
        stroke: var(--border-container);
      }
    }
  }

  .leave-message {
    width: 100%;
    max-width: var(--viewport-max-mobile-width);
  }

  .row, .sep {
    max-width: var(--viewport-max-mobile-width);
    width: 100%;
  }

  .row {
    display: flex;
    
    > * {
      flex: 1 1 0;
      
      &:nth-of-type(1) {
        margin-right: 10px;
      }
    }
  }
}