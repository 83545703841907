.context-menu {
  position: relative;
  top: 5px;
  // display: flex;
  // border-radius: 6px;
  // border: 2px solid var(--primary-1, #1E1E1E);
  // background: var(--bg, #FEFEFE);
  // gap: 8px;
  // padding: 16px;
  // filter: drop-shadow(2px 2px 0px #0D0D0D);
}

.context-menu-wrapper {
  display: flex;
  position: fixed;
  white-space: nowrap;
  transform: translate(-50%, 0);
  z-index: 100000;

}

.context-menu-trigger {
  display: flex;
  flex-direction: column;

  > * {
    width: 100%;
    height: 100%;
  }
  // height: 100%;
}