.settings-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  overflow: auto;

  > .avatar-con {
    width: 125px;
    height: 125px;
  }

  > p {
    margin: 20px 0;
    font-size: 30px;
  }

  > a {
    margin: 10px 0;
    width: 100%;
    border: 1px solid var(--border-container);
    border-radius: 16px;
    padding: 15px;

    .settings-card {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > svg {
        transform: rotate(180deg);
      }

      > div {
        display: flex;
        align-items: center;

        svg {
          padding: 5px;
          background-color: var(--not-active);
          width: 32px;
          height: 32px;
          border-radius: 50px;
          stroke: var(--background-focused-black);
          margin-right: 10px;
        }
      }
    }
  }
}

.logout-modal {
  display: flex;
  flex-direction: column;

  > p.subheader {
    margin: 20px 0;
  }

  > * {
    margin: 5px 0;
  }
}