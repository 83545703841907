.nft-card {
  display: flex;
  flex-direction: column;
  width: 160px;
  background: linear-gradient(144.703deg, rgba(193, 49, 90, 0.2), rgba(254, 51, 76, 0.2), rgba(255, 154, 3, 0.2));
  border-radius: 8px;
  overflow: hidden;
  padding: 1px;
  position: relative;


  .circle{
    height: 18px;
    min-width: 18px;
    margin: var(--spacing-sm);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--white);
    padding: 3px;

    p{
      color: var(--white);
      font-size: var(--font-size-md);
    }
  }

  .check-con{
    background-color: var(--background-modal);
    border-radius: var(--bradius-md);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: var(--spacing-sm);
  }

  .nft-content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: var(--background-app);
    border-radius: 8px;
    position: relative;

    .one-image{
      // position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 158px;
      height: 158px;

      .circle{
        position: absolute;
        top: var(--spacing-sm);
        right: var(--spacing-sm);
        display: flex;
        height: 18px;
        min-width: 18px;
        justify-content: center;
        align-items: center;
        background-color: var(--white);
        border-radius: var(--bradius-md);
        overflow: hidden;

        p{
          color: var(--black);
        }
      }

      img {
        width: 158px;
        height: 158px;
      }
    }

    .two-images{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: var(--spacing-sm);
      height: 154px;

      img{ // For real, what the actual fuck is this?? This is not react native
        margin: calc(var(--spacing-sm) / 2) 0;
        height: calc(154px / 2 - var(--spacing-sm) - var(--spacing-sm) / 2);
        width: calc(154px / 2 - var(--spacing-sm) - var(--spacing-sm) / 2);
      }
    }

    .three-images{
      display: flex;
      flex-direction: column;

      div{
        display: flex;
        align-items: center;
        justify-content: space-between;

        img{
          margin: calc(var(--spacing-sm) / 2);
          height: calc(158px / 2 - var(--spacing-sm) - var(--spacing-sm) / 2);
          width: calc(158px / 2 - var(--spacing-sm) - var(--spacing-sm) / 2);
        }
      }

      img{
        align-self: center; // This can be done in web, instead of doing this shit^^
        margin: calc(var(--spacing-sm) / 2) 0;
        height: calc(158px / 2 - var(--spacing-sm) - var(--spacing-sm) / 2);
        width: calc(158px / 2 - var(--spacing-sm) - var(--spacing-sm) / 2);
      }

    }

    .four-images {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-sm);

      > div {
        height: calc(158px / 2 - var(--spacing-sm) / 2);
        display: flex;
        gap: var(--spacing-sm);
      }

      img {
        height: 100%;
        // width: calc(158px / 2);
        // height: calc(158px / 2);
      }
    }

    svg {
      width: 28px;
      height: 28px;
      stroke: var(--accent-primary);
      position: absolute;
      right: 0;
      top: 145px;
      background: var(--background-app);
      padding: 5px;
      border-radius: 50px;
    }

    .nft-image {
      width: 158px;
      height: 158px;

      .circle{
        position: absolute;
        top: var(--spacing-sm);
        left: var(--spacing-sm);
        display: flex;
        height: 18px;
        min-width: 18px;
        justify-content: center;
        align-items: center;
        background-color: var(--white);
        border-radius: var(--bradius-md);
        overflow: hidden;

        p{
          color: var(--black);
        }
      }
    }

    .token-img{
      width: 79px;
      height: 79px;
      margin-top: 29px;
    }

    .info {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: var(--spacing-sm);

      .text-line{
        display: flex;
        flex-wrap: nowrap;

        > p {
          font-size: 12px;
          line-height: 14px;
        }
      }

      > p {
        font-size: 12px;
        line-height: 14px;
      }

      .accent {
        color: var(--accent-primary);
      }

      .gr {
        color: var(--border-container);
      }

      .price-container {
        display: flex;
        flex: 1;
        // align-self: flex-end;
        align-items: center;

        .free {
          color: var(--accent-primary);
          line-height: 15px;
        }

        p:not(.free) {
          margin-left: 5px;
          line-height: 15px;
        }

        img {
          height: 20px;
          width: 20px;
          border-radius: 10px;
        }
      }
    }
  }
}
