@font-face {
    font-family: "Chillax-Bold";
    src: url("../assets/fonts/Chillax/Chillax-Bold.otf");
}

@font-face {
    font-family: "Chillax-Semibold";
    src: url("../assets/fonts/Chillax/Chillax-Semibold.otf");
}

@font-face {
    font-family: "Chillax-Regular";
    src: url("../assets/fonts/Chillax/Chillax-Regular.otf");
}

@font-face {
    font-family: "Chillax-Medium";
    src: url("../assets/fonts/Chillax/Chillax-Medium.otf");
}

@font-face {
    font-family: "Chillax-Light";
    src: url("../assets/fonts/Chillax/Chillax-Light.otf");
}

@font-face {
    font-family: "Chillax-Extralight";
    src: url("../assets/fonts/Chillax/Chillax-Extralight.otf");
}