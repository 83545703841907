.connect-wallet {
    display: flex;
    flex-direction: column;
    background: url("../../assets/LandingBackground.svg") ;
    background-size: cover;
    height: 100vh;
    position: relative;


    .main {
        padding: 0 var(--spacing-xl);
        display: flex;
        flex-grow: 2;
        flex-direction: column;
        max-width: 700px;
        text-align: center;
        margin: 0 auto;
        overflow-y: auto;
        
        .banner {
            margin: var(--spacing-xxl) 0 var(--spacing-lg) 0;
        }
        .subheader {
            margin-bottom: var(--spacing-xxl);
        }

        .info-box {
            display: flex;
            flex-direction: column;
            margin-top: var(--spacing-xl);
            border: 1px solid rgba(255, 255, 255, 0.4);
            background-color: rgba(0, 0, 0, 0.2);
            padding: var(--spacing-xxl);
            border-radius: var(--bradius-xl);

            .header{
                margin-top: var(--spacing-xxxl);
                margin-bottom: var(--spacing-xl);
            }

            .header-1 {
                margin-top: var(--spacing-xl);
                margin-bottom: var(--spacing-xl);
            }

            .connect-button {
                align-self: center;
                margin-top: var(--spacing-xl);

                svg {
                    fill: white;
                    margin-right: 5px;
                }

                button {
                    cursor: pointer;
                    background-color: var(--accent-primary);
                    color: var(--text-white);
                    outline: none;
                    border-radius: var(--bradius-xxxl);
                    padding: 0 80px;

                }
            }

            .colored-button-inverse {
                margin-top: var(--spacing-md);
                align-self: center;
                // width: 50%;

            }
        }
    }
}