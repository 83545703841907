.text-copy {
  display: flex;
  background: var(--background-input);
  width: fit-content;
  align-items: center;
  padding: 1px 3px;
  border-radius: 5px;

  p {
    font-size: 10px;
    color: var(--text-gray);
  }

  > svg {
    width: 12px;
    height: 12px;
    margin-left: 3px;
  }
}