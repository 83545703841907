.footer {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: var(--spacing-lg);

    > div {
      display: flex;
      align-items: center;
    }

    .subheader {
      margin-left: 5px;
    }

    .social {
      margin: 0 var(--spacing-xl);
      
      img {
        width: 24px;
      }
    }
  
    img {
      display: flex;
      height: 35px;
    }
  }