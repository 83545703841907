.feedback {
  display: flex;
  flex-direction: column;
  padding: 15px;
  height: 100%;

  > p.normal {
    margin: 10px 0;
  }

  > button {
    margin-top: auto;
  }
}