$theme: (
    --viewport-max-mobile-width: 700px,
    --viewport-max-width-button: 450px,
    --menu-height: 80px,
    --header-height: 64px,
    
    --spacing-sm: 4px,
    --spacing-md: 8px,
    --spacing-lg: 10px,
    --spacing-xl: 12px,
    --spacing-xxl: 24px,
    --spacing-xxxl: 32px,
    --spacing-xnft-header-height: 65px,
    --spacing-xnft-header-width: 58px,
    
    --accent-primary: #ee9d12,
    --accent-orange-deep: #6f271c,
    --background-app: #232223,
    --background-modal: #333333,
    --background-input: #191918,
    --background-focused-black: #0a0a0d,
    --background-toast: #e1e7ec,
    --text-white: #f2f2f2,
    --text-dirty-white: #cfdaec,
    --text-gray: #aaaaaa,
    --text-toast-default: #3887cb,
    --placeholder-text: #667085,
    --main-text-dark: #030e20,
    --text-red: #dc3545,
    --border-blur-gray: rgba(242, 242, 242, 0.2),
    --border-focused-white: #cfdaec,
    --border-container: #4e4e4e,
    --light-gray: #727888,
    --medium-gray: #282929,
    --gray: #4e4e4e,
    --white: #ffffff,
    --black: #0a0a0d,
    --gradient-accents-pale: (rgba(193, 49, 90, 0.2), rgba(254, 51, 76, 0.2), rgba(255, 154, 3, 0.2)),
    --gradient-warm: (#8a3513, #ee9d12),
    --success-green: green,
    --alert-red: #f23f42,
    --sub-text-dark: #737373,
    --sub-text-light: #9a9a9a,
    --not-active: #727888,
    --menu-dark: #2b2b2b,
    --shadow: rgba(0, 0, 0, 0.7),
    
    --bradius-none: 0px,
    --bradius-sm: 4px,
    --bradius-md: 8px,
    --bradius-lg: 12px,
    --bradius-xl: 16px,
    --bradius-xxl: 20px,
    --bradius-xxxl: 24px,
    --bradius-round: 50px,
    
    --font-size-sm: 10px,
    --font-size-md: 12px,
    --font-size-lg: 14px,
    --font-size-xl: 16px,
    --font-size-xxl: 18px,
    --font-size-xxxl: 24px,
    --font-size-extra-large: 30px,
    
    --line-height-sm: 12px,
    --line-height-md: 15px,
    --line-height-lg: 28px,
    --line-height-xl: 20px,
    --line-height-xxl: 22px,
    --line-height-xxxl: 24px,
    --line-height-extra-large: 28px,
    
    --shadow-sm: 2px 2px 2px rgba(0,0,0,0.7),
    --shadow-md: 4px 4px 4px rgba(0,0,0,0.7),
    --shadow-lg: 6px 6px 6px rgba(0,0,0,0.7),
    --shadow-xl: 10px 10px 10px rgba(0,0,0,0.7),
);
    

@mixin spread-map($map: ()) {
    @each $key, $value in $map {
        #{$key}: $value;
    }
}