.feed {
  display: flex;
  flex-direction: column;
  gap: 25px;
  
  img {
    width: 55px;
    height: 55px;
  }
  
  .items {
    display: flex;
    flex-direction: column;
    gap: 8px;

    > div {
      border-radius: 8px;
      padding: 15px;
      display: flex;
      gap: 15px;
      border-radius: 8px;
      background: rgba(0, 0, 0, 0.21);
      align-items: center;
      justify-content: flex-start;

      .images {
        display: flex;
        flex-direction: column;
      }

      .info {
        display: flex;
        flex-wrap: wrap;
        gap: 12px 30px;
        width: 100%;

        > div {
          display: flex;
          flex-direction: column;
          flex: 1 0 0;
          min-width: 140px;
          max-width: fit-content;
          // min-width: fit-content;
          gap: 10px;

          @media (max-width: 520px) {
            width: 100%;
            flex: 1 0 100%;
            flex-direction: row;
            max-width: unset !important;

            p {
              font-size: 12px;
              white-space: unset !important;

              &.gray {
                width: 40px;
              }

              &.accented {
                
              }
            }
            // flex: 1 0 0;
          }

          > p, > div > * {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          &.name {
            // min-width: 150px;
            max-width: 140px;
          }

          &.time-container {
            // width: 100%;
            // max-width: unset;

            > div {
              display: flex;
              align-items: center;
              gap: 8px;

              svg {
                width: 20px;
                height: 20px;
                stroke: rgba(255, 255, 255, 0.80);;
              }
            }

          }
        }
      }
    }
  }

  .navigator {
    display: flex;
    align-self: center;
    gap: 8px;
    align-items: center;
    user-select: none;

    .rotate {
      svg {
        transform: rotate(180deg);
      }
    }

    svg {
      margin: 0 16px;

    }

    > .page-selector {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 8px;
      background: rgba(0, 0, 0, 0.21);
      
      &.selected {
        border: 1px solid var(--orrange, #E2A03C);

      }
    }
  }

}