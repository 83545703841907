.quantity-ticker{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 22px;
    margin: var(--spacing-md) 0;
    color: var(--white);
    
    .ticker-input{
      height: 22px;
      width: 110px;
      background-color: var(--background-input);
      color: var(--white);
      border: none;
      text-align: center;
    }

    .ticker{
      height: 25px;
      width: 25px;
      border-radius: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
}