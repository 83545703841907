.sidepanel {
  position: fixed;
  left: -100%;
  top: 0;
  height: 100svh;
  background: var(--background-input);
  z-index: 10000;
  padding: 24px 16px;
  border-radius: 0 16px 16px 0;

  &.open {
    left: 0;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    

    > div {
      cursor: pointer;


      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  .content {
    height: 100%;
  }
}