.mynfts-page {
  display: flex;
  flex-direction: column;
  padding: 15px;

  .search-input-container {
    margin: 25px 0;
  }

  .nft-container {
    display: flex;
    flex-wrap: wrap;
  }

  .banner {
    position: absolute;
    top: 25px;
  }
}