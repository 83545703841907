.cta-button {
    margin-bottom: var(--spacing-xl);
    border-radius: var(--bradius-lg);
    width: 100%;

  }

.cta-connect-button {
  width: 100%;
    margin-top: var(--spacing-xl);

    svg {
        fill: white;
        margin-right: 5px;
    }

    button {
        cursor: pointer;
        background-color: var(--accent-primary);
        color: var(--text-white);
        outline: none;
        border-radius: var(--bradius-xxxl);
        display: flex;
        justify-content: center;
    }
}
  .linCon {
    width: 100%;
    border-radius: var(--bradius-lg);
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: var(--spacing-md);
    // ...SHADOW.MD
  }
  .notInShopCon {
    display: flex;
    flex-direction: row;
    background-color: var(--medium-gray);
    justify-content: space-around;
    align-items: center;
    padding: var(--spacing-md);
  }
  .purchased {
    flex-direction: row;
    justify-content: center;
    padding: var(--spacing-md);
  }
  .cta-image {
    height: 25px;
    width: 25px;
  }
  .soldRightCon {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-evenly;
    margin-left: var(--spacing-md);
  }
  .soldLeftCon {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }