@import "./variables";
@import "./fonts.scss";
@import "./text.scss";
@import "./lists.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

p {
  margin: 0;
}

a {
  color: unset;
  text-decoration: none;
}

.wallet-adapter-dropdown {
  width: unset !important;
}

.touchable {
  cursor: pointer;
}

body {
  overflow-x: hidden;
}

// Ported from the expo app

html,
body,
#root {
    width: 100%;
    max-width: 100svw;
    /* To smooth any scrolling behavior */
    -webkit-overflow-scrolling: touch;
    margin: 0px;
    padding: 0px;
    /* Allows content to fill the viewport and go beyond the bottom */
    min-height: 100%;
    overflow-x: hidden;
}

#root {
    flex-shrink: 0;
    flex-basis: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    flex: 1;
}

html {
    scroll-behavior: smooth;
    /* Prevent text size change on orientation change https://gist.github.com/tfausak/2222823#file-ios-8-web-app-html-L138 */
    -webkit-text-size-adjust: 100%;
    height: calc(100% + env(safe-area-inset-top));
    background: var(--background-app);
    @include spread-map($theme);
}

body {
    display: flex;
    /* Allows you to scroll below the viewport; default value is visible */
    overflow-y: auto;
    overscroll-behavior-y: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-overflow-style: scrollbar;
}

.disabled {
  pointer-events: none;
}

.mb-lg{
  margin-bottom: var(--spacing-lg);
}

.mb-xxxl{
  margin-bottom: var(--spacing-xxxl);
}