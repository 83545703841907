.hub-page-wrapper {
  background: linear-gradient(160deg, #1B202B 0%, #2F1F29 28.90%, #1B202B 68.84%, #2F1F29 100%);
  background-size: cover;
  background-repeat: no-repeat;
}

.hub-page {
  z-index: 1000000;
  display: flex;
  flex-direction: column;
  padding: 20px;

  .custom-tab {
    padding: 20px 0;
  }

  p.gray {
    color: rgba(255, 255, 255, 0.80);
  }

  p.accented {
    color: var(--accent-primary);
  }

  .controls {
    display: flex;
    border-bottom: 1px solid var(--gray);
    justify-content: space-between;
    margin-top: 10px;

    
    .tabs {
      margin: 20px 0 0 0;
      display: flex;
      gap: 50px;
      padding-bottom: 5px;
  
      > div {
        position: relative;
      }
      
      p {
        color: var(--text-gray);
      }
  
      > div.active {
  
        p {
          color: var(--accent-primary);
        }
        div {
          position: absolute;
          width: 100%;
          height: 1px;
          bottom: -6px;
          background-image: linear-gradient(90deg, rgb(138, 53, 19), rgb(238, 157, 18));
        }
      }
    }
    
    .filters {
      display: flex;
      align-items: center;
      gap: 10px;
      
    }
    @media (max-width: 500px) {
      flex-direction: column;
      border-bottom: none;
      gap: 15px;
  
      .tabs {
        justify-content: space-between;
        border-bottom: 1px solid var(--gray);
        gap: 10px;

        p {
          font-size: 12px;
        }
      }

      .filters {
        gap: 10px;
        justify-content: center;
        border-radius: 6px;
        border: 1px solid #716C6F;
        padding: 12px;
    
      }
    }
  }
  
  
  .top {
    overflow: hidden;
    position: relative;
    border-radius: 18px;
    border: 1px solid #7C7779;
    background: rgba(0, 0, 0, 0.7);
  }
  
  .logo {
    width: fit-content;
  }
  
  div.banner {
    z-index: -1;
    position: absolute;
    top: 0%;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.3;
    // height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .description {
    display: flex;
    flex-direction: column;
    gap: 15px;

    p.normal {
      font-size: var(--font-size-xl);
    }

    &.mob {
      display: none;
      margin: 16px 0;
    }

    .social {
      display: flex;
      gap: 16px;

      > a {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        border: 1px solid #E2A03C;
        padding: 7px;

        > svg {
          width: 32px;
          height: 32px;

        }
      }
    }


  }
  
  .top-content {

    display: flex;
    padding: 16px 16px 32px 16px;
    gap: 15px;

    .img-container {
      
      > img {
        width: 180px;
        height: 180px;
      }

      // @media (max-width: 400px) {
        // display: flex;
        // justify-content: center;
        // > img {
        //   width: 100%;
        //   height: auto;
        // }
      // }

    }
    
    > div {
      display: flex;
      flex-direction: column;
    }

    .metadata {
      display: flex;
      width: 150px;
      max-width: 150px;
      margin-left: auto;
      flex: 1 0 150px;
      gap: 5px;
      padding-top: 8px;
      
      > div {
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.67);
        padding: 9px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        p.gray {
          color: rgba(255, 255, 255, 0.80);
        }
      }
    }
    @media (max-width: 700px) {
      .img-container {
        display: none;
      }
  
      & {
        flex-direction: column;
      }

      .metadata {
        flex-direction: row;
        width: 100%;
        max-width: unset;
        margin-left: 0;
        flex: 1 0 auto;
        
        > div {
          // height: fit-content;
          flex: 1 0 auto;

          p {
            font-size: 12px;
          }
        }
      }
    }

  }

  .img-container {
    
    img {
      border-radius: 10px;
    }
  }
}

.top-shops {
  display: flex;
  flex-direction: column;

  input {
    background: transparent;
  }

  select {
    font-family: 'Chillax-Regular';
    // background: var(--background-input);
    background: transparent;
    color: var(--text-dirty-white);
    outline: none;
    border: 1px solid var(--border-container);
    padding: 10px;
    border-radius: 8px;
    font-size: 16px;

    > option {
      display: flex;

      .weighted {
        font-family: 'Chillax-Semibold';
      }
    }
  }

  .sort-menu {
    // top: 0;
    // position: absolute;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background: #1D1C1D;
    box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.10);
    padding: 8px;
    gap: 8px;
    
    > div {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 15px;
      border-radius: 4px;
      padding: 4px 8px;

      svg {
        width: 20px;
        height: 20px;
      }

      &.active {
        background: var(--background-modal);
      }
    }

  }

  .cont {
    display: flex;
    // align-items: center;
    // justify-content: space-between;
    margin-bottom: 10px;
    gap: 8px;

    .search-input-container {
      margin: 0 !important;
      width: 100%;
      margin-right: 8px;
    }
    
    .fbt {
      cursor: pointer;
      padding: 6px 8px;
      background: var(--background-modal);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .content {
    min-height: 300px;
  }

  .pagination {
    margin-top: 15px;
  }
}