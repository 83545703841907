.pagination {
  display: flex;
  align-self: center;
  gap: 8px;
  align-items: center;
  user-select: none;

  .rotate {
    svg {
      transform: rotate(180deg);
    }
  }

  svg {
    margin: 0 16px;

  }

  > .page-selector {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.21);
    
    &.selected {
      border: 1px solid var(--orrange, #E2A03C);

    }
  }
}