.jesus {
  height: 40vh;
  position: absolute;
  top: calc(var(--spacing-xl) * -1);
  width: 100%;
  z-index: -1;
}

.itemsale-header{
  top: var(--spacing-xl);
}

.item-sale {
  padding: 0 var(--spacing-xl) 0 var(--spacing-xl);
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .tabs {
    width: 100%;
    margin: 20px 0 0 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--gray);
    padding-bottom: 5px;

    > div {
      position: relative;
    }

    p {
      color: var(--text-gray);
    }

    > div.active {

      p {
        color: var(--text-white);
      }
      div {
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: -6px;
        background-image: linear-gradient(90deg, rgb(138, 53, 19), rgb(238, 157, 18));
      }
    }
  }

  .metadata-wrapper {
    width: 100%;
    max-width: var(--viewport-max-width-button);
    display: flex;
    flex-direction: column;

    .carousel {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin: var(--spacing-xl) 0;

      .img-con{
        margin: 0 var(--spacing-md);
        height: 85px;
        width: 85px;
        border-radius: var(--bradius-md);
        overflow: hidden;
        position: relative;
        cursor: pointer;

        img{
          height: 100%;
          width: 100%;
        }

      }
      .active{
        border: 4px solid var(--accent-primary);
      }

      .circle{
        position: absolute;
        top: var(--spacing-sm);
        right: var(--spacing-sm);
        display: flex;
        height: 18px;
        min-width: 18px;
        justify-content: center;
        align-items: center;
        background-color: var(--white);
        border-radius: var(--bradius-md);
        overflow: hidden;

        p{
          color: var(--black);
        }
      }
    }

    .one-image{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30vh;
      height: 30vh;
      align-self: center;
      border-radius: 2px;
      overflow: hidden;

      img {
        width: 30vh;
        height: 30vh;
      }
    }

    > a button {
      width: 100%;
    }

    .col {
      display: flex;
      align-items: center;
      margin-top: 0;

      svg {
        margin-left: 10px;
        stroke: var(--accent-primary);
      }

      > * {
        color: var(--accent-primary);

      }
    }

    .ct {
      text-align: center;
    }

    .gr {
      color: var(--text-gray);
    }

    .user-tile {
      background: var(--background-input);
      padding: 10px;
      border-radius: 8px;

      .avatar-container {
        margin-right: 5px;
      }

      .usnm {
        margin-bottom: 5px;
      }

      .red-dot {
        width: 3px;
        height: 3px;

        background: var(--accent-orange-deep);
      }

      svg {
        transform: rotate(180deg);
      }

      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div {
          display: flex;
          align-items: center;

          > div {
            display: flex;
            flex-direction: column;

            > div {
              display: flex;
              align-items: center;

              > * {
                margin: 0 2px;
              }
            }
          }
        }

      }
    }

    > button {
      margin: 5px 0;
    }

    .currency-modal-ref {
      position: relative;

      .currency-modal {
        position: absolute;
        bottom: 30px;
        right: 0;
        background: var(--medium-gray);
        padding: var(--spacing-md);
        border-radius: 8px;
        border: 1px solid var(--gray);

        > div {
          display: flex;
        }
      }
    }

    > img {
      align-self: center;
      height: 30vh;
      border-radius: 8px;
    }

    .row {
      display: flex;
      justify-content: space-between;

      > div {
        display: flex;
        align-items: center;

        > img {
          width: 20px;
          height: 20px;
          border-radius: 50px;
          margin-right: 10px;
        }
      }
    }

  }

}

.delist-modal {
  display: flex;
  flex-direction: column;

}

.update-modal {
  display: flex;
  flex-direction: column;

}

.orangeCircle {
  height: 4px;
  width: 4px;
  border-radius: 2px;
  background-color: var(--accent-orange-deep);
}
.profileCon {
  cursor: pointer;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  background-color: var(--background-input);
  border-radius: var(--bradius-md);
  padding: var(--spacing-md);
}
.profileConOuter {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background-color: var(--background-app);
  border: none;
}

.confetti-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // min-height: 20vh;

  .scg {
    width: 200px;
  }

}

.itemsale-backCon{
  position: absolute;
  top: var(--spacing-xl);
  left: var(--spacing-xl);
}

.itemsale-menuCon{
  position: absolute;
  top: var(--spacing-xl);
  right: var(--spacing-xl);
}

.description {
  // margin-bottom: var(--spacing-xl);

  .more {
    cursor: pointer;
    color: var(--accent-primary);
    text-decoration: underline;
  }
}
