.manage-connected {
  display: flex;
  flex-direction: column;
  padding: 15px;
  align-items: center;
  height: 100%;

  > .text-copy {
    p {
      font-size: 14px;
    }
  }

  button {
    margin-top: auto;
    width: 100%;
  }
}

.sr {
  flex: 1 0 auto;
}