.create-stache {
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    display: flex;
    align-items: center;
    padding: 0 var(--spacing-xl);
    position: relative;
    justify-content: space-between;

    svg {
      height: 25px;
      width: 25px;
    }
    

    .connectBut {
      padding: var(--spacing-lg);
      justify-content: center;
      background-color: var(--background-input);
      border-radius: var(--bradius-lg);
      border: 1px solid var(--accent-primary);
      background-color: transparent;
      height: 35px;

      .connectText {
        color: var(--accent-primary);
      }
    }
  }

  .content {
    padding: var(--spacing-xl);
  }

  .submit {
    margin-top: auto;
    padding: 0 15px 20px 15px;
    width: 100%;
    display: flex;

    > button {
      width: 100%;
    }
  }

}