.create-listing {
  padding: 15px;
  display: flex;
  flex-direction: column;
  height: 100%;

  .main-con{
    display: flex;
    flex-direction: column;
    flex: 1;

    .selected-items{
      margin: var(--spacing-lg) 0 0 0;
      color: var(--accent-primary);
  
      li{
        margin-bottom: var(--spacing-md);
  
        p{
          color: var(--white);
        }
      }
    }
  
    > p.header {
      font-size: 36px;
      font-weight: 700;
      margin-bottom: 15px;
    }
  
    > p.normal {
      color: var(--not-active);
    }
  
    select {
      font-family: 'Chillax-Regular';
      background: var(--background-input);
      color: var(--text-dirty-white);
      outline: none;
      border: 1px solid var(--border-container);
      padding: 10px;
      border-radius: 8px;
      font-size: 16px;
    }
  
    > * {
      margin: var(--spacing-lg) 0;
    }
  
    > div {
      display: flex;
      flex-direction: column;
    }
  
    > button {
      margin-top: auto;
    }
  }

}

.listing-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  .success {
    height: 100px;
    width: 100px;

    img {
      height: 100%;
      width: 100%;
    }
  }

  button, a {
    width: 100%;
  }
}