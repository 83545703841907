.user-card {
  display: flex;
  flex-direction: column;
  width: 160px;
  height: 230px;
  background: linear-gradient(144.703deg, rgba(193, 49, 90, 0.2), rgba(254, 51, 76, 0.2), rgba(255, 154, 3, 0.2));
  border-radius: 8px;
  overflow: hidden;
  padding: 1px;
  
  .user-content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    background: var(--background-app);
    border-radius: 8px;
    padding-top: var(--spacing-lg);

    .placeholder {
      aspect-ratio: 1;
      background: linear-gradient(144.703deg, rgba(193, 49, 90, 0.2), rgba(254, 51, 76, 0.2), rgba(255, 154, 3, 0.2));
    }

    img, .placeholder {
      width: 158px;
      height: 158px;
      border-radius: 100px;
    }

    .info {
      padding: 5px;

      .id {
        text-align: center;
        margin: 8px 0;
      }

      > div {
        display: flex;
      }
      
      > p {
        font-size: 12px;
      }
    
      .gr {
        color: var(--text-gray);
        margin-right: 5px;
      }
    }
  }
}

.topshop-card {
  display: flex;
  align-items: center;
  
  .userInfo{
    display: flex;
    flex-direction: column;

    div{ 
      display: flex;
      column-gap: var(--spacing-sm);
    }
  }
}