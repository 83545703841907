.notAuthedCon {
    padding: 0 var(--spacing-xl) 0 var(--spacing-xl);
    background-color: transparent;
    z-index: 999;
    position: relative;

    .topCon {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      background-color: transparent;
      z-index: 999;
      height: 35px;
    }

    .connectBut {
      width: 100%;
      padding: var(--spacing-lg);
      justify-content: center;
      align-items: center;
      display: flex;
      background-color: var(--background-input);
      border-radius: var(--bradius-lg);
      border: 1px solid var(--accent-primary);
      background-color: transparent;
      height: 35px;

      .connectText {
        color: var(--accent-primary);
      }
    }

    .overlayCon {
      position: absolute;
      left: 0;
      right: 0;
      background-color: var(--background-input);
      z-index: -1;
      width: 100%;
      top: calc((var(--spacing-xl) + 35px) * -1);
      height: calc(100% + var(--spacing-xl) + 35px);
    }

    .overlay-xnft{
      top: calc((var(--spacing-xnft-header-height) + var(--spacing-xl) + 35px) * -1);
      height: calc(100% + (var(--spacing-xnft-header-height) + var(--spacing-xl) + 35px));
    }

    // .overlay-xnft-w-header{
    //   top: calc(var(--spacing-xnft-header-height) * -1);
    //   height: calc(100% + var(--spacing-xnft-header-height));
    // }

    .middleCon {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      padding-top: var(---spacing-xxxl);
  
      .linksCon {
        border-bottom-color: var(--gray);
        border-bottom-width: 1px;
        padding: var(--spacing-xl);
      }
  
      .bottomCon {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: var(--spacing-xl) 0;
  
        .linCon {
          width: 100%;
          max-width: var(--viewport-max-mobile-width);
          border-radius: var(--bradius-lg);
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1px;
          z-Index: 999;
          // ...SHADOW.MD,
        }
      }
    }

    .row {
      margin: var(--spacing-lg) 0;
      color: var(--text-white);

      p {
        cursor: pointer;
      }
    }
  
    .socialsCon {
      display: flex; 
      margin-top: var(--spacing-lg); 
      margin-bottom: var(--spacing-lg); 
      align-items: center;
      justify-content: center;

      .socialButton {
        margin: 0 var(--spacing-xxxl);
      }
    }
}

