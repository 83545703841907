.toasts {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 var(--spacing-lg);
  bottom: 70px;
  z-index: 999;

  p {
    text-align: center;
  }
  
  .toastCon {
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    min-width: 30%
  };
  
  .innerCon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: var(--bradius-sm);
    width: 100%;
    max-width: 400px;
    padding: var(--spacing-md);
  };
  
  .hideCon {
    display: flex;
    border-width: 1px;
    border-radius: var(--bradius-sm);
    border-color: var(--colors-white);
    padding: var(--spacing-sm);
    justify-content: center;
    align-items: center;
  };

}
