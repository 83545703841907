.notifications-con {
  padding-top: 0;

  background-color: var(--background-app);

  .empty-con{
    display: flex;
    flex-direction: column;
    flex: 1; 
    justify-content: center;
    padding: 0 var(--spacing-xl) var(--spacing-xl) var(--spacing-xl);
    align-items: center;
  }

  .content-con{
    display: flex;
    padding: 0 var(--spacing-xl) var(--spacing-xl) var(--spacing-xl);
    flex: 1; 
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .notification {
      border-bottom: 1px solid var(--text-gray);
      padding: 10px 0;
  
      .gr {
        color: var(--text-gray);
      }
    }
  }
}
.loaderCon {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-app);
}
.image {
  width: 168px;
  height: 168px;
  margin-bottom: var(--spacing-xl);
}