.con {
    justify-content: flex-end;
    display: flex;
    align-items: center;


    .topCon {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: transparent;
        z-index: 999;
        height: 35;
        margin-left: var(--spacing-md);
    }
      
    .overlayCon {
      position: absolute;
      top: 0;
      right: 0;
      background-color: var(--background-input);
      padding-top: calc(35px + (var(--spacing-xl) * 2));
      padding-left: 16px;
      padding-right: 16px;


      .linksCon {
        align-items: 'flex-end';

        .row {
          margin-top: var(--spacing-md);
          margin-bottom: var(--spacing-md);

          
          .text {
            cursor: pointer;
            white-space: nowrap;
            flex-wrap: nowrap;
            color: var(--text-white);
            font-family: 'chillax-regular';
            font-size: var(--font-size-lg);
            line-height: var(--line-height-lg);
          }
        }
      }
    }
}