.bazaar {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;

  .hub-promo {
    display: flex;
    flex-direction: column;
    height: 70px; // change to 150px when there are more hubs
    flex-wrap: wrap;
    align-content: start;
    gap: 15px 50px;
    // justify-content: flex-start;

    > * {
      // flex: 1 0 0;
      // width: fit-content;
      height: 60px;

      img {
        width: 200px;
      }
    }
  }

  .controls {
    display: flex;
    border-bottom: 1px solid var(--gray);
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 15px;

    
    .tabs {
      margin: 20px 0 0 0;
      display: flex;
      gap: 50px;
      padding-bottom: 5px;
  
      > div {
        position: relative;
      }
      
      p {
        color: var(--text-gray);
      }
  
      > div.active {
  
        p {
          color: var(--accent-primary);
        }
        div {
          position: absolute;
          width: 100%;
          height: 1px;
          bottom: -6px;
          background-image: linear-gradient(90deg, rgb(138, 53, 19), rgb(238, 157, 18));
        }
      }
    }
    
    .filters {
      display: flex;
      align-items: center;
      gap: 10px;
      
    }
    @media (max-width: 500px) {
      flex-direction: column;
      border-bottom: none;
      gap: 15px;
  
      .tabs {
        justify-content: space-between;
        border-bottom: 1px solid var(--gray);
        gap: 10px;

        p {
          font-size: 12px;
        }
      }

      .filters {
        gap: 10px;
        justify-content: center;
        border-radius: 6px;
        border: 1px solid #716C6F;
        padding: 12px;
    
      }
    }
  }

  .content, .exp {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-xl);
    padding-top: 0;
  }

  .bazaar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--spacing-xl) var(--spacing-xl) var(--spacing-xl);

    svg {
      fill: var(--accent-primary);
    }
  }

  .top-shops {
    display: flex;
    margin-top: var(--spacing-sm);
    max-width: 100%;
    overflow: auto;

    .shopCon{
      // flex: 1 0 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: var(--spacing-lg);
      row-gap: var(--spacing-md);
    }

    > a > div {
      display: flex;
      align-items: center;
      margin-right: 30px;

      .gr {
        color: var(--text-gray);
      }

      .ct {
        margin: 0 5px;
      }

      > div {
        display: flex;
        flex-direction: column;
        
        > div {
          display: flex;
        }
      }
    }
  }

  .listings {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.usercard{
  display: flex;
  align-items: center;

  .userInfo{
    div{
      display: flex;
      
      p{
        margin-right: var(--spacing-sm);
      }
    }
  }
}