.nav-footer {
  display: flex;
  justify-content: space-evenly;
  background: var(--menu-dark);
  padding: 5px 0 0 0;
  width: 100vw;
  border-top-left-radius: var(--bradius-lg);
  border-top-right-radius: var(--bradius-lg);
  box-shadow: 1px 2px 15px var(--shadow);

  p{
    color: var(--not-active);
  }

  > a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > svg {
      width: 25px;
      height: 25px;
      stroke: var(--) !important;

      &:not(.bazaar) {
        fill: none;
      }
    }

    &.selected {

      > svg, > p {
        stroke: var(--accent-primary) !important;
        color: var(--accent-primary);

        &.bazaar {
          fill: var(--accent-primary);
        }
      }
    }
  }
}