.playgroundCon {
    flex: 1;
    display: flex;
    overflow: auto;
    flex-direction: column;
    padding: var(--spacing-xl);
    background-color: var(--background-app);
    justify-content: space-around;
    align-items: center;

    
    .section {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;
      max-width: var(--viewport-max-mobile-width);
      justify-content: center;

      .innerText{
        font-size: 10px; 
        color: var(--text-dirty-white);
        margin-bottom: var(--spacing-md);
      }

      .innerCon {
        display: flex;
        flex-direction: row;
        align-items: center;

        .text {
          margin-left: var(--spacing-md);
          color: var(--text-white);
        }
      }

      .buttons {
        max-width: var(--viewport-max-mobile-width);
        width: 100%;
      }
    }
    
    .divider {
      height: 1px;
      width: 60%;
      margin: var(--spacing-xxl) 0;
      border-radius: 1px;
      background-color: var(--gray);
    }
}