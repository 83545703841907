.modalCon {
  max-height: 80%;
  width: calc(100vw - var(--spacing-xl) * 2 + 2px);
  max-width: calc(var(--viewport-max-mobile-width) - (var(--spacing-xl) * 2));
  border-radius: var(--bradius-lg);
  border-width: 0.5px;
  overflow: auto;
  align-self: center;
  background-color: var(--menu-dark);
  padding: var(--spacing-lg);

  &:Has(.confetti-modal) {
    width: fit-content;
  }

  
  // .modalConBottom: {
  //   width: '100%',
  //   backgroundColor: Theme.COLORS.BACKGROUND_MODAL,
  //   padding: Theme.SPACING.MD,
  //   position: "absolute",
  //   bottom: 0,
  //   left: 0,
  //   right: 0,
  //   borderTopLeftRadius: Theme.BRADIUS.XXL,
  //   borderTopRightRadius: Theme.BRADIUS.XXL
  // }
}