.sale-conversation-con{
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  overflow-y: auto;
  min-height: 0px;
  padding: var(--spacing-md) 0 75px 0;

    // .conversation-con {
    //   display: flex;
    //   height: 100%;
    //   justify-content: center;
    //   flex-direction: column;
    //   background-color: var(--background-app);
    //   padding-top: var(--spacing-md);
      
    // }

    .botCon {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 var(--spacing-xl);
        padding: var(--spacing-xl) 0;
        background-color: var(--background-app);
        
        .main{
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
        }

        .commentButtons {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          margin-top: var(--spacing-xl);
        }
    }


    .commentYellow {
      display: flex;
      background-color: var(--accent-primary);
      padding: var(--spacing-md);
      border-radius: var(--bradius-lg);
      justify-content: center;
      align-items: center;
    }

    .repliesCon {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: var(--background-focused-black);
      padding-top: 10px;
      border-top-left-radius: var(--bradius-sm);
      border-top-right-radius: var(--bradius-sm);
    }
}

.messageCon {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    padding: var(--spacing-sm);
    border-radius: var(--bradius-sm);
    margin-left: var(--spacing-sm);
    border-width: 0.5;
    border-color: var(--gray);
    background-color: var(--background-modal);
}
