.multilisting-con{
    display: flex;
    flex-direction: column;
    padding: var(--spacing-xl);
 
    .card-contents{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}