.avatar-container {
  background: var(--gray);
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
  }
}