.wrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom-width: 1;
    border-bottom-color: var(--gray);
  }
  .tab {
    cursor: pointer;
    height: 35px;
    padding-bottom: 1px;

  }
  .activeTab{
    background: linear-gradient(to right, #8a3513, #ee9d12);
  }

  .inactiveTab{
      background-color: linear-gradient(to right, var(--background-app), var(--background-app));
  }
  .textCon {
    height: 33px;
    background-color: var(--background-app);
  }