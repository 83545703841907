.link-wallet {
  display: flex;
  flex-direction: column;
  height: 100%;

  
  .header {
    display: flex;
    align-items: center;
    padding: var(--spacing-xl) var(--spacing-xxl);
    position: relative;
    justify-content: space-between;
    
    svg {
      height: 25px;
      width: 25px;
    }
    
    
    button {
      cursor: pointer;
      background-color: transparent;
      display: flex;
      justify-content: center;
      color: var(--text-white);
      outline: none;
      border: 1px solid var(--accent-primary);
      border-radius: var(--bradius-xxxl);
      padding: 0 20px;
    }
  }
  
  .content {
    padding: var(--spacing-xl);

    > p.normal {
      margin-top: 30px;
    }

    > p.medium {
      color: var(--placeholder-text);
      a {
        margin-left: 3px;
        color: var(--accent-primary);
      }
    }
  }

  .submit {
    margin-top: auto;
    padding: 0 15px 20px 15px;
    width: 100%;
    display: flex;

    > button {
      width: 100%;
    }
  }
}

.link-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  img, svg {
    height: 150px;
    width: fit-content;
  }

  > p {
    margin: 10px 0 20px 0;
    text-align: center;
  }

  .white-button {
    background-color: white;
    > p {
      color: var(--accent-primary);
      
    }
  }

  > button {
    width: 100%;
    margin: 5px 0;
    margin-top: auto;
  }
}