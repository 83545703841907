.input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-lg);

  input, textarea {
    border-radius: 8px;
    outline: none;
    border: none;
    background: var(--background-input);
    padding: 10px;
    color: var(--text-white);
    font-family: 'Chillax-Semibold';
  }

  textarea {
    min-height: 100px;
    resize: vertical;
    max-height: 600px;
    
  }
}

.search-input-container {
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid rgba(242, 242, 242, 0.2);
  border-radius: 8px;
  margin-bottom: var(--spacing-xl);

  svg {
    position: absolute;
    top: 9px;
    left: 8px;
    // fill: white;
  }

  input {
    border-radius: 8px;
    outline: none;
    border: none;
    background: var(--background-input);
    padding: 10px;
    color: var(--text-white);
    font-family: 'Chillax-Semibold';
    padding-left: 35px;
  }
}