.loader-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: var(--background-app);
  height: 100%;
  z-index: 10000000;

  &.nobg {
    background-color: unset;
  }
}