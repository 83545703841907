.modal-login {
  display: flex;
  flex-direction: column;
  align-items: center;

  img, svg {
    height: 125px;
    width: fit-content;
  }

  > p {
    margin: 10px 0 20px 0;
    text-align: center;
  }

  .white-button {
    background-color: var(--border-focused-white);
    > p {
      color: var(--background-focused-black);
      
    }
  }

  > button {
    width: 100%;
  }
}