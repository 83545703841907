.home-page {
  padding: var(--spacing-xl);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  .main-view {
    display: flex;
    justify-content: center;
    flex: 1;
  }

  .home-header {
    display: flex;
    justify-content: space-between;

    .avatar-container {
      margin-right: 5px;
    }
    
    .left-container {
      display: flex;
      align-items: center;
    }
  }
  .balance-container {
    p.normal {
      color: var(--text-gray);
    }
    p.header {
      color: var(--text-dirty-white);
      font-size: 18px;
    }
  }

  > .text-copy {
    > p {
      font-size: 14px;
    }
  }

  .select-wallet {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    select {
      font-family: 'Chillax-Regular';
      background: var(--background-input);
      color: var(--text-dirty-white);
      outline: none;
      border: 1px solid var(--border-container);
      padding: var(--spacing-md);
      border-radius: 8px;
      font-size: 12px;
    }
  }

  .tabs {
    margin: 20px 0 0 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--gray);
    padding-bottom: 5px;

    > div {
      position: relative;
    }

    p {
      color: var(--text-gray);
    }

    > div.active {

      p {
        color: var(--text-white);
      }
      div {
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: -6px;
        background-image: linear-gradient(90deg, rgb(138, 53, 19), rgb(238, 157, 18));
      }
    }
  }
}

.tokens-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .token {
    margin: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      display: flex;
      align-items: center;
    }

    .dk {
      color: var(--sub-text-dark);
    }

    img {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      margin-right: 5px;
    }
  }
}

.history-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}