p.banner {
  font-family: 'Chillax-Semibold';
  font-size: var(--font-size-extra-large);
  line-height: var(--line-height-extra-large);
  color: var(--text-white);
}

p.header {
  font-family: 'Chillax-Regular';
  font-size: var(--font-size-xxxl);
  line-height: var(--line-height-xxxl);
  color: var(--text-white);
}

p.subheader {
  font-family: 'Chillax-Semibold';
  font-size: var(--font-size-lg);
  line-height: var(--line-height-lg);
  color: var(--text-white);
}

p.normal {
  font-family: 'Chillax-Regular';
  font-size: var(--font-size-lg);
  line-height: var(--line-height-xl);
  color: var(--text-white);
}

p.medium {
  font-family: 'Chillax-Medium';
  font-size: var(--font-size-lg);
  line-height: var(--line-height-lg);
  color: var(--text-white);
}

p.thin {
  font-family: 'Chillax-Extralight';
  font-size: var(--font-size-md);
  line-height: var(--line-height-md);
  color: var(--text-white);
}

p.error {
  font-family: 'Chillax-Regular';
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
  color: var(--text-red);
}

p.hint {
  font-family: 'Chillax-Regular';
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
  color: var(--placeholder-text);
}

a.banner {
  font-family: 'Chillax-Semibold';
  font-size: var(--font-size-extra-large);
  line-height: var(--line-height-extra-large);
  color: var(--text-white);
}

a.header {
  font-family: 'Chillax-Regular';
  font-size: var(--font-size-xxxl);
  line-height: var(--line-height-xxxl);
  color: var(--text-white);
}

a.subheader {
  font-family: 'Chillax-Semibold';
  font-size: var(--font-size-lg);
  line-height: var(--line-height-lg);
  color: var(--text-white);
}

a.normal {
  font-family: 'Chillax-Regular';
  font-size: var(--font-size-lg);
  line-height: var(--line-height-xl);
  color: var(--text-white);
}

p.medium {
  font-family: 'Chillax-Medium';
  font-size: var(--font-size-lg);
  line-height: var(--line-height-lg);
  color: var(--text-white);
}

a.thin {
  font-family: 'Chillax-Extralight';
  font-size: var(--font-size-md);
  line-height: var(--line-height-md);
  color: var(--text-white);
}

a.error {
  font-family: 'Chillax-Regular';
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
  color: var(--text-red);
}

a.hint {
  font-family: 'Chillax-Regular';
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
  color: var(--placeholder-text);
}

a, p {
  font-family: 'Chillax-Regular';
}