.manage-pending-page {
  display: flex;
  flex-direction: column;
  padding: 0 15px 15px 15px;
  align-items: center;
  height: 100%;

  > * {
    margin: 15px 0;
  }

  > .text-copy {
    margin-top: 0;
    p {
      font-size: 14px;
    }
  }

  .info-box {
    display: flex;
    padding: 10px;
    border-radius: 16px;
    background: var(--background-toast);
    align-items: center;

    svg {
      stroke: var(--accent-primary);
      margin-right: 10px;
    }

    p {
      color: var(--text-toast-default);
    }
  }

  .steps {
    display: flex;
    flex-direction: column;
    background: var(--menu-dark);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 9px;
    border-radius: 12px;
    padding: 10px;

    
    .sep {
      width: 3px;
      height: 25px;
      background-color: green;
      margin: 5px 0 5px 13px;
    }
    
    .empty-circle {
      background: white;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      margin-right: 10px;
      
      > div {
        border-radius: 50px;
        width: 10px;
        height: 10px;
        background-color: rgb(204, 204, 204);
      }
    }

    .big-circle {
      background: white;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      margin-right: 10px;
      
      > div {
        border-radius: 50px;
        width: 10px;
        height: 10px;
        background-color: green;
      }
    }
    
    .item {
      display: flex;
      margin-left: 5px;
      align-items: center;
      
      &.withbig {
        margin-left: 0;
      }

      svg {
        margin-right: 10px;
      }
    }
  }

  .votes-container {
    display: flex;
    flex-direction: column;
    
    > div {
      display: flex;
      flex-direction: column;
      margin: 10px 0;

      > div {
        display: flex;

        img, >div {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }

        > div {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
          border-radius: 50px;

          p {
            color: var(--background-app);
          }
        }
      }
    }
  }
  
  .actions {
    display: flex;
    margin-top: auto;
    width: 100%;
    margin-bottom: 0;

    gap: 10px;

    > * {
      flex: 1 0 auto;
    }
  }
}

.linking-modal {
  display: flex;
  flex-direction: column;
}