div.grid {
    display: grid;
    width: 100%;
    margin: auto;
    justify-content: center;
    margin-top: var(--spacing-md);
    grid-gap: var(--spacing-md);
    grid-template-rows: repeat(auto-fit, 230px);
    grid-template-columns: repeat(auto-fit, 160px);

    .grid-item{
        grid-row: span 1;
        grid-column: span 1;
    }
}