.asset-details {
  display: flex;
  flex-direction: column;
  .img-container {
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;

    img {
      // width: auto;
      height: 100%;
      object-fit: contain;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 15px;

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin: 5px 0;
    }


    > button, > a {
      align-items: center;

      > button {
        width: 100%;
      }

      > svg {
        transform: rotate(180deg);
        width: 10px;
        height: 10px;
      }
    }

    .tabs {
      margin: 20px 0 0 0;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--gray);
      padding-bottom: 5px;
  
      > div {
        position: relative;
      }
  
      p {
        color: var(--text-gray);
      }
  
      > div.active {
  
        p {
          color: var(--text-white);
        }
        div {
          position: absolute;
          width: 100%;
          height: 1px;
          bottom: -6px;
          background-image: linear-gradient(90deg, rgb(138, 53, 19), rgb(238, 157, 18));
        }
      }
    }
  }

}
.sales-description {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;

  > .row {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;

      > * {
        margin-left: 10px;
      }
    }
  }
}

.sales-attributes {
  display: flex;
  flex-wrap: wrap;
  width: 75%;
  align-self: center;
  margin: 0 auto;
  
  > div {
    flex: 1 0 20%;
    margin: 10px;
    display: flex;
    flex-direction: column;
    background: var(--background-input);
    padding: 10px;
    border-radius: 8px;

    .gr {
      color: var(--text-gray);
    }
    
  }
}

.sendnft-modal {
  display: flex;
  flex-direction: column;

  > button {
    margin: 10px 0;
  }
}

   
.divider{
  width: 75%;
  margin: var(--spacing-xxl) auto var(--spacing-sm) auto;
  height: 1px;
  background-color: var(--gray);
  border-radius: 1px;
}

.attribute{
  width: 100%;
  .header-name{
    h4{
      color: var(--text-dirty-white);
      margin: var(--spacing-md) 0;
    }
  }
}

